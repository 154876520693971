<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="PRIVILEGE"
        :filter="filter"
        placeholder="Search Name, Detail, Privilege Code"
        :hasDropdown="false"
        @search="handleSearch"
        @sidebar="sidebarFilter"
        routerPath="/privilegecode/details/0"
      />

      <div>
        <SideBarFilter
          ref="sidebarFilter"
          :filter="filter"
          :placeholder="'Search Name, Detail, Privilege Code'"
          @searchAll="handleSearch"
          @clearFilter="clearFilter"
        >
          <template v-slot:filter-option>
            <div class="mt-3">
              <div>
                <div class="font-weight-bold mb-2">Valid From</div>
                <div :class="['input-container']">
                  <datetime
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    v-model="filter.start_date"
                    ref="startDate"
                    placeholder="Please Select Date"
                  >
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.startDate.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer color-primary"
                      color="#B41BB4"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-3 mb-3">
                <div class="font-weight-bold mb-2">Valid To</div>
                <div :class="['input-container']">
                  <datetime
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    v-model="filter.end_date"
                    :min-datetime="filter.start_date"
                    placeholder="Please Select Date"
                    ref="endDate"
                  >
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.endDate.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer color-primary"
                      color="#B41BB4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </SideBarFilter>
      </div>
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(image_url)="data">
                <div
                  class="d-flex justify-content-center"
                  v-if="data.item.image_url"
                >
                  <b-img
                    class="box-image1-1"
                    :src="data.item.image_url"
                    fluid
                    @error="handleImageSrc"
                  ></b-img>
                </div>
                <div v-else class="d-flex justify-content-center">
                  <b-img
                    class="box-image1-1"
                    src="/img/default-placeholder.png"
                    fluid
                  ></b-img>
                </div>
              </template>
              <template v-slot:cell(validFrom)="data">
                <div>{{ data.item.validFrom | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.validFrom | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(validTo)="data">
                <div>{{ data.item.validTo | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.validTo | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div v-if="data.item.status == 1" class="text-success">
                  Active
                </div>
                <div v-else class="text-danger">Inactive</div>
              </template>
              <template v-slot:cell(name)="data">
                <div class="justify-content-center text-left">
                  <router-link :to="'/privilegecode/details/' + data.item.id">
                    <u> {{ data.item.name }}</u>
                  </router-link>
                  <div class="line-clamp2">
                    {{ data.item.short_description }}
                  </div>
                  <div class="badge-container">
                    <div
                      class="badge badge-secondary"
                      v-for="(coupon, index) in data.item.tag_coupon"
                      :key="index + '_' + coupon.id"
                    >
                      {{ coupon.name }}
                    </div>
                    <div
                      class="badge badge-secondary"
                      v-for="(coupon, index) in data.item.tag_stamp"
                      :key="index + '_' + coupon.id"
                    >
                      {{ coupon.name }}
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SideBarFilter from "@/components/SideBarFilter";
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderMenuPrivilege from "@/components/privilegeDetail/HeaderMenuPrivilege";
import Pagination from "@/components/Pagination";
export default {
  components: {
    OtherLoading,
    SideBarFilter,
    HeaderMenuPrivilege,
    Pagination,
  },
  name: "PrivilegeCodeIndex",
  data() {
    return {
      fields: [
        {
          key: "image_url",
          label: "Image",
          thStyle: { width: "15%" },
          tdClass: "p-3",
        },
        {
          key: "name",
          label: "Name / Detail",
          thStyle: { width: "35%" },
        },
        {
          key: "prefix",
          label: "Privilege Code",
        },
        {
          key: "validFrom",
          label: "Valid From",
        },
        {
          key: "validTo",
          label: "Valid To",
        },
        {
          key: "sort_order",
          label: "No.",
        },
        {
          key: "status",
          label: "Status",
        },
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      checkAll: false,
      selectAllCb: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      checkboxActive: false,
      checkboxInactive: false,
      filter: {
        search: "",
        page: 1,
        take: 10,
        status: [],
        start_date: "",
        end_date: "",
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selected: 0,
      isLoading: true,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },
  created: async function () {
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.status =
        this.filter.status.length == 1 ? this.filter.status[0] : null;
      payload.start_date =
        payload.start_date == "" ? "0001-01-01" : payload.start_date;
      payload.end_date =
        payload.end_date == "" ? "0001-01-01" : payload.end_date;
      this.isLoadingData = true;
      await this.$store.dispatch("ActionGetPrivilegeCode", payload);
      var data = await this.$store.state.modulePrivilegeCode
        .stateGetPrivilegeCode;
      this.isLoading = false;
      if (data.result == 1) {
        this.items = data.detail.detail;
        this.rows = data.detail.total_count;
        this.isLoadingData = false;
      }
      this.isBusy = false;
    },
    handleSearch(value) {
      this.filter = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        status: [],
        start_date: "",
        end_date: "",
      };
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show(true);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.time {
  color: #afafaf;
}
.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.badge {
  font-size: 85%;
  background-color: var(--theme-secondary-color);
  color: var(--primary-color);
  max-width: max-content;
  font-weight: 100;
}
.badge-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>
